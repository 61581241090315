<template>
    <div>
      <div class="header">
          Business Matching
      </div>
  
      <div class="container mb-5">
            <!-- HTML -->
            <div class="filter-section">
            <div class="category-filter d-flex align-items-center mb-3 mb-md-0">
                <label for="category" class="form-label me-2">Category</label>
                <select class="form-select" id="category">
                <option selected>All</option>
                <option value="1">Category 1</option>
                <option value="2">Category 2</option>
                <option value="3">Category 3</option>
                </select>
            </div>
            <div class="search-bar d-flex align-items-center">
                <input type="text" class="form-control custom-search-input" placeholder="Search">
                <button class="btn search_btn ms-1 w-25"><i class="fas fa-search"></i></button>
            </div>
            </div>

  
          <!-- Product List -->
          <div class="product-list">
              <!-- Item 1 -->
              <div class="product-item">
                  <div class="product-info product-block">
                      <div class="product-logo bg-secondary">SC</div>
                      <div class="product-details">
                          <p>Product</p>
                          <h5>Product Name 1</h5>
                      </div>
                  </div>
                  <div class="product-info company-block">
                      <div class="product-logo bg-secondary">SC</div>
                      <div class="product-details">
                          <p>Company name, Address</p>
                          <p>email@example.com</p>
                      </div>
                  </div>
                  <div class="product-actions">
                      <button class="btn bg-dark rounded-pill text-light w-100 mb-2">Matching</button>
                      <button class="btn btn-outline-secondary rounded-pill w-100">Messenger</button>
                  </div>
              </div>
  
              <!-- Item 2 -->
              <div class="product-item">
                  <div class="product-info product-block">
                      <div class="product-logo bg-primary">SC</div>
                      <div class="product-details">
                          <p>Product</p>
                          <h5>Product Name 2</h5>
                      </div>
                  </div>
                  <div class="product-info company-block">
                      <div class="product-logo bg-primary">SC</div>
                      <div class="product-details">
                          <p>Company name, Address</p>
                          <p>email@example.com</p>
                      </div>
                  </div>
                  <div class="product-actions">
                    <button class="btn bg-dark rounded-pill text-light w-100 mb-2">Matching</button>
                      <button class="btn btn-outline-secondary rounded-pill w-100">Messenger</button>
                  </div>
              </div>
  
              <!-- Item 3 -->
              <div class="product-item">
                  <div class="product-info product-block">
                      <div class="product-logo bg-danger">SC</div>
                      <div class="product-details">
                          <p>Product</p>
                          <h5>Product Name 3</h5>
                      </div>
                  </div>
                  <div class="product-info company-block">
                      <div class="product-logo bg-danger">SC</div>
                      <div class="product-details">
                          <p>Company name, Address</p>
                          <p>email@example.com</p>
                      </div>
                  </div>
                  <div class="product-actions">
                    <button class="btn bg-dark rounded-pill text-light w-100 mb-2">Matching</button>
                      <button class="btn btn-outline-secondary rounded-pill w-100">Messenger</button>
                  </div>
              </div>
  
              <!-- Item 4 -->
              <div class="product-item">
                  <div class="product-info product-block">
                      <div class="product-logo bg-warning">SC</div>
                      <div class="product-details">
                          <p>Product</p>
                          <h5>Product Name 4</h5>
                      </div>
                  </div>
                  <div class="product-info company-block">
                      <div class="product-logo bg-warning">SC</div>
                      <div class="product-details">
                          <p>Company name, Address</p>
                          <p>email@example.com</p>
                      </div>
                  </div>
                  <div class="product-actions">
                    <button class="btn bg-dark rounded-pill text-light w-100 mb-2">Matching</button>
                      <button class="btn btn-outline-secondary rounded-pill w-100">Messenger</button>
                  </div>
              </div>
  
              <!-- เพิ่มรายการใหม่ตามสีเดิม -->
              <div class="product-item">
                  <div class="product-info product-block">
                      <div class="product-logo bg-secondary">SC</div>
                      <div class="product-details">
                          <p>Product</p>
                          <h5>Product Name 5</h5>
                      </div>
                  </div>
                  <div class="product-info company-block">
                      <div class="product-logo bg-secondary">SC</div>
                      <div class="product-details">
                          <p>Company name, Address</p>
                          <p>email@example.com</p>
                      </div>
                  </div>
                  <div class="product-actions">
                    <button class="btn bg-dark rounded-pill text-light w-100 mb-2">Matching</button>
                      <button class="btn btn-outline-secondary rounded-pill w-100">Messenger</button>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CommunityPage'
  }
  </script>

<style>
    .search_btn{

        background: linear-gradient(90deg, #0D8BC7 0%, #1E6598 100%);
        color:#fff;
    }
    .header {
        background-image: url('https://marketweb.9ood.net/assets/bg-mini-header.jpg');
        background-size: cover;
        background-position: center;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ff6f47;
        font-size: 2rem;
        font-weight: bold;
    }

        /* CSS */
        .filter-section {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        padding: 1rem;
        background-color: #ffff;
        border-radius: 8px;
        }

        .category-filter, .search-bar {
        display: flex;
        flex: 1;
        }

        .category-filter select, .search-bar input {
        width: 100%;
        }

        .search-bar button {
        padding: 0.5rem 1rem;
        }

        @media (max-width: 768px) {
        .filter-section {
            flex-direction: column;
            gap: 0.5rem;
        }

        .search-bar {
            width: 100%;
        }

        .search-bar button {
            width: 100%;
            margin-top: 0.5rem;
        }
        }


    .product-list {
        border: 1px solid #ddd;
        border-radius: 10px;
        padding: 20px;
        background-color: #f9f9f9;
    }

    .product-item {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 10px 0;
        border-bottom: 1px solid #ddd;
    }

    .product-block, .company-block {
        display: flex;
        align-items: center;
    }

    .product-logo {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        color: white;
        margin-right: 15px;
    }

    .product-details {
        margin-right: 50px;
    }

    .product-actions {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    /* Responsive Styles */
    @media (min-width: 768px) {
        .product-item {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .product-block, .company-block {
            flex: 1;
            margin-right: 20px;
        }

        .product-actions {
            width: 20%;
            margin-left: auto;
            display: flex;
            flex-direction: column;
        }
    }
</style>  