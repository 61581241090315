<!-- src/components/NotFoundPage.vue -->
<template>
  <div class="not-found">
    <h1>404 - ไม่พบหน้านี้</h1>
    <p>ขออภัย! หน้าที่คุณต้องการไม่สามารถค้นหาได้</p>
    <router-link to="/" class="btn btn-main">กลับไปหน้าหลัก</router-link>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage'
}
</script>

<style scoped>
.not-found {
  text-align: center;
  margin-top: 150px;
}
</style>
