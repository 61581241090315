// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router';
import Home from './components/HomePage.vue';
import About from './components/AboutPage.vue';
import Contact from './components/ContactPage.vue';
import Product from './components/ProductPage.vue';
import Service from './components/ServicePage.vue';
import Coffee from './components/CoffeePage.vue';
import Community from './components/CommunityPage.vue';
import NotFound from './components/NotFoundPage.vue'; // นำเข้า NotFoundPage
import './assets/main.css'; // นำเข้าไฟล์ CSS

// กำหนดเส้นทาง
const routes = [
  { path: '/', component: Home },
  { path: '/about', component: About },
  { path: '/contact', component: Contact },
  { path: '/product', component: Product },
  { path: '/services', component: Service },
  { path: '/community', component: Community },
  { path: '/coffee', component: Coffee },
  { path: '/:pathMatch(.*)*', component: NotFound }, // เส้นทางสำหรับหน้าไม่พบหน้านี้
];

// สร้าง router
const router = createRouter({
  history: createWebHistory(),
  routes
});

// สร้างและติดตั้งแอปพลิเคชัน Vue
const app = createApp(App);
app.use(router); // ใช้ router
app.mount('#app');

window.addEventListener('load', () => {
  document.body.classList.remove('loading');
  document.body.classList.add('loaded');
});
