<template>
  <div>

    <!-- Background image section -->
    <div class="background-image">
        <div class="container">
        <div class="row ">
        <div class="col-md-8 col-12">
            <div class="branding">
                <img src="@/assets/logo-xl.png" class="mt-5 w-100">
            </div>
        </div>
        </div>
            <div class="row mt-5 ">
                
                <div class="col-md-4 col-12">
                   <div class="auth-container" style="background-color:#f0f0f0; border-radius:15px; padding:20px;">
  <div class="auth-buttons mb-3">
    <button class="btn btn-dark rounded-pill w-100 mb-3">Register</button>
    <a href="https://shop.sinothaitrade.com/index.php/checkout" class="btn btn-outline-dark rounded-pill w-100">Sign In</a>
  </div>
  <div class="social-login text-center">
    <p class="mb-2">Or continue with</p>
        <button class="btn btn-outline-success me-2"><i class="fab fa-weixin"></i></button> <!-- WeChat -->
<button class="btn btn-outline-primary me-2"><i class="fab fa-qq"></i></button> 
   <!-- <button class="btn btn-outline-primary me-2"><i class="fab fa-facebook-f"></i></button>-->
    <!--   <button class="btn btn-outline-danger me-2"><i class="fab fa-google"></i></button>-->
    <!--<button class="btn btn-outline-dark me-2"><i class="fab fa-twitter"></i></button>-->
    <button class="btn btn-outline-secondary"><i class="fab fa-apple"></i></button>

  </div>
</div>


                </div>
                <div class="col-md-4 col-12">
                    <p class="description">
                    โครงการพัฒนานโยบายด้านการค้า การลงทุนรูปแบบใหม่เพื่อสนับสนุน และส่งเสริมการค้าและการลงทุนในพื้นที่ระเบียงเศรษฐกิจ ระหว่างไทย-จีน
                    </p>
                </div>


            </div>
        </div>
    </div>


    <div class="container py-5">
      
        <div class="row p-5" style="background-color:#F8FBFB; border-radius:30px;">
            <div class="col-12">  <h2 class="text-center mb-5 primary-text">Sino Thai Trade Products and Services</h2> </div>
            <div class="col-md-4 mb-4">
                <div class="service-card">
                    <img src="@/assets/online_marketing.png" class="h-50" alt="Online Market">
                    <div class="service-text p-3">
                        <h3 class="primary-text">Online Market</h3>
                      
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-4">
                <div class="service-card">
                    <img src="@/assets/bissness_matching.png" class="h-50" alt="Business Matching">
                    <div class="service-text p-3">
                        <h3 class="primary-text">Business Matching</h3>
                     
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-4">
                <div class="service-card">
                    <img src="@/assets/lawandregulation.png" class="h-50" alt="Law and Regulation">
                    <div class="service-text p-3">
                        <h3 class="primary-text">Law and Regulation</h3>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>


  </div>
</template>

<script>
export default {
  name: 'HomePage' // เปลี่ยนชื่อเป็นหลายคำ
}
</script>


<style >

.auth-container {
  max-width: 400px;
  margin: 0 auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.auth-buttons button {
  padding: 10px 15px;
  font-size: 16px;
}

.social-login p {
  font-size: 14px;
  color: #666;
}

.social-login button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Body section */
.background-image {
    width: 100%;
    height: 85vh;
    background-image: url('@/assets/bg-main.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.service-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.service-card {
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    overflow: hidden;
}

.service-card img {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
}

.service-text {
    text-align: center;
    margin-top: 15px;
}

.primary-text{
    color:#013E77;
}
</style>
