<template>
  <div>
    <div class="container py-5">
      <div class="row">
        <div class="col-12 col-md-6">
          <!-- Bestsellers Section -->
          <div class="product-section">
            <h2 class="section-title">Bestsellers</h2>
            <div v-if="loadingBestsellers" class="loader">Loading...</div>
            <div v-else class="product-grid">
              <div v-for="(product, index) in bestsellers" :key="index" class="product-card">
                <a :href="product.product_link" target="_blank">
                  <img 
                    :src="product.product_image" 
                    :alt="product.product_name" 
                    @error="handleImageError($event)" 
                  />
                  <p>{{ product.product_name }}</p>
                </a>
              </div>
            </div>
            <div class="view-more">
              <a href="https://shop.sinothaitrade.com/index.php/show-cart">View more</a>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <!-- Technology & Innovation Section -->
          <div class="product-section">
            <h2 class="section-title">Technology & Innovation</h2>
            <div v-if="loadingTechProducts" class="loader">Loading...</div>
            <div v-else class="product-grid-min">
              <div v-for="(product, index) in techProducts" :key="index" class="product-card">
                <a :href="product.product_link" target="_blank">
                                <img 
                :src="product.product_image" 
                :alt="product.product_name" 
                @error="handleImageError" 
                :style="{ width: '200px', height: '200px', objectFit: 'cover' }"
              />

                  <p>{{ product.product_name }}</p>
                </a>
              </div>
            </div>
            <div class="view-more">
              <a href="#">View more</a>
            </div>
          </div>

          <!-- Craft Product Section -->
          <div class="product-section">
            <h2 class="section-title">Craft Product</h2>
            <div v-if="loadingCraftProducts" class="loader">Loading...</div>
            <div v-else class="product-grid-min">
              <div v-for="(product, index) in craftProducts" :key="index" class="product-card">
                <a :href="product.product_link" target="_blank">
                  <img 
  :src="product.product_image" 
  :alt="product.product_name" 
  @error="handleImageError" 
  :style="{ width: '200px', height: '200px', objectFit: 'cover' }"
/>

                  <p>{{ product.product_name }}</p>
                </a>
              </div>
            </div>
            <div class="view-more">
              <a href="#">View more</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductPage',
  data() {
    return {
      bestsellers: [],
      techProducts: [],
      craftProducts: [],
      loadingBestsellers: true,
      loadingTechProducts: true,
      loadingCraftProducts: true
    };
  },
  mounted() {
    // Fetch Bestsellers (4 items)
    fetch('https://shop.sinothaitrade.com/index.php/Api_data/product_random/4')
      .then(response => response.json())
      .then(data => {
        this.bestsellers = data.product.slice(0, 4); // Limit to 4 items
        this.loadingBestsellers = false;
      })
      .catch(error => {
        console.error('Error fetching bestsellers:', error);
        this.loadingBestsellers = false;
      });

    // Fetch Technology & Innovation (3 items)
    fetch('https://shop.sinothaitrade.com/index.php/Api_data/product_random/3')
      .then(response => response.json())
      .then(data => {
        this.techProducts = data.product.slice(0, 3); // Limit to 3 items
        this.loadingTechProducts = false;
      })
      .catch(error => {
        console.error('Error fetching tech products:', error);
        this.loadingTechProducts = false;
      });

    // Fetch Craft Products (3 items)
    fetch('https://shop.sinothaitrade.com/index.php/Api_data/product_random/3')
      .then(response => response.json())
      .then(data => {
        this.craftProducts = data.product.slice(0, 3); // Limit to 3 items
        this.loadingCraftProducts = false;
      })
      .catch(error => {
        console.error('Error fetching craft products:', error);
        this.loadingCraftProducts = false;
      });
  },
  methods: {
    handleImageError(event) {
      event.target.src = 'https://placehold.co/400'; // เปลี่ยน path ตรงนี้ให้เป็น path ของรูปภาพสำรอง
    }
  }
};
</script>

<style>
/* Add your styling here */
.section-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ff6f47;
}

.product-section {
  background-color: #f8f8f8;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 30px;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.product-grid-min {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.product-card {
  border-radius: 10px;
  overflow: hidden;
}
.product-card img {
  width: 100%;
  height: 300px; /* กำหนดความสูงให้เท่ากับความกว้าง */
  object-fit: cover; /* ทำให้ภาพเต็มพื้นที่และ crop ส่วนเกิน */
  border-radius: 10px;
}
.view-more {
  text-align: right;
  font-size: 0.9rem;
  margin-top: 10px;
}

.view-more a {
  color: #007bff;
  text-decoration: none;
}

.view-more a:hover {
  text-decoration: underline;
}

.loader {
  text-align: center;
  font-size: 1.2rem;
  color: #666;
  padding: 20px;
}
</style>
