<template>
  <div>
    <h1>ติดต่อเรา</h1>
    <p>คุณสามารถติดต่อเราผ่านทาง...</p>
  </div>
</template>

<script>
export default {
  name: 'ContactPage' // เปลี่ยนชื่อเป็นหลายคำ
}
</script>
