<template>
    <div class="container P-5 mt-5 mb-5">
    
  
        <h2 class="text-center">การส่งออกกาแฟผง</h2>

        <hr>

<h3>ขอบเขตการควบคุม</h3>
<p>ผลกาแฟ เมล็ดกาแฟไม่ว่าจะคั่ว บด หรือแยกเอาสารคาเฟอีนออกแล้วหรือไม่ก็ตาม และผลิตภัณฑ์ที่มีสิ่งสกัดหัวเชื้อและสิ่งเข้มข้นของกาแฟผสมอยู่ไม่น้อยกว่าร้อยละ 36.54</p>

<h3>ระเบียบและหลักเกณฑ์</h3>
<ol>
  <li>จดทะเบียนเป็นผู้ส่งออกกาแฟรับอนุญาต</li>
  <li>ต้องขออนุญาตส่งออก โดยจะอนุญาตให้ส่งออก 2 กรณี คือ
    <ul>
      <li>(1) กรณีส่งออกกาแฟที่ผลิตภายในประเทศ</li>
      <li>(2) กรณีส่งออกกาแฟที่ผลิตจากเมล็ดกาแฟดิบที่นำเข้าจากต่างประเทศ</li>
    </ul>
  </li>
  <li>ต้องขอหนังสือรับรองถิ่นกำเนิดสินค้าตามข้อบังคับขององค์การกาแฟระหว่างประเทศ แต่ไม่รวมถึงผลิตภัณฑ์กาแฟซึ่งผลิตจากเมล็ดกาแฟดิบที่นำเข้าจากต่างประเทศ</li>
  <li>ต้องรายงานปริมาณรับซื้อ ปริมาณการส่งออก ปริมาณคงเหลือและสถานที่เก็บกาแฟผ่านระบบรายงานการส่งออก-นำเข้าของกรมการค้าต่างประเทศที่เว็บไซต์ <a href="http://e-report.dft.go.th">http://e-report.dft.go.th</a> เป็นประจำทุกเดือนภายใน 7 วันทำการของเดือนถัดไป</li>
</ol>

<p>Link: <a href="https://www.dft.go.th/th-th/DFT-Service/Data-Service-Information/ProductMeasure-Import-Export/Detail-ProductMeasure-Import-Export/ArticleId/1950/2551-20">https://www.dft.go.th/th-th/DFT-Service/Data-Service-Information/ProductMeasure-Import-Export/Detail-ProductMeasure-Import-Export/ArticleId/1950/2551-20</a></p>


<div class="container mb-3">
    <img src="@/assets/coffee-img0.jpg" class="img-fluid" alt="Coffee Image 0">
</div>


        <h2>สรุปขั้นตอนการส่งออกกาแฟผงไปจีน</h2><div class="table-responsive">
<table class="table table-bordered">
  <thead class="thead-dark">
    <tr>
      <th>ลำดับ</th>
      <th>ขั้นตอน</th>
      <th>รายละเอียด</th>
      <th>หน่วยงานผู้รับผิดชอบ</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>จดทะเบียนนิติบุคคล</td>
      <td>
        ต้องเป็นนิติบุคคลที่จดทะเบียนและมีวัตถุประสงค์เพื่อประกอบพาณิชยกิจเป็นผู้ค้ากาแฟ หรือสินค้าทางการเกษตรออกไปจำหน่วยต่างประเทศ โดย
ผู้ประกอบการจะได้รับการยกเว้นและสิทธิต่าง ๆ ในการขอคืนภาษีซึ่งผู้ประกอบการจะต้องมีสถานะเป็นนิติบุคคลเท่านั้น

      </td>
      <td>กรมพัฒนาธุรกิจการค้า</td>
    </tr>
    <tr>
      <td>2</td>
      <td>จดทะเบียนภาษีมูลค่าเพิ่ม</td>
      <td></td>
      <td>กรมสรรพากร</td>
    </tr>
    <tr>
      <td>3</td>
      <td>ทำบัตรประจำตัวผู้ส่งออก-นำเข้าสินค้า</td>
      <td>
        <b>จดทะเบียนเป็นผู้ส่งออกกาแฟรับอนุญาตและขอหมายเลขประจำตัวผู้ส่งออก</b><br><br>

<b>เอกสาร/ใบรับรองที่จะได้รับ</b>
<ul>
  <li>บัตรผู้ส่งออก-นำเข้าสินค้ากาแฟรับอนุญาตและหมายเลขประจำตัวผู้ส่งออก</li>
</ul>

<b>หลักฐานที่ใช้ยื่น</b>
<ul>
  <li>คำร้องขอมีบัตรประจำตัวผู้ส่งออก-นำเข้าสินค้า</li>
  <li>สำเนาหนังสือรับรองการจดทะเบียนบริษัท ไม่เกิน 3 เดือน</li>
  <li>สำเนาหนังสือรับรองการจดทะเบียนภาษีมูลค่าเพิ่ม</li>
  <li>สำเนาบัตรประชาชนกรรมการหรืออื่น</li>
</ul>


      </td>
      <td>กรมการค้าต่างประเทศ</td>
    </tr>
    <tr>
      <td>4</td>
      <td>จดทะเบียนเป็นผู้ส่งออกกาแฟรับอนุญาตและขอหมายเลขประจําตัวผู้ส่งออก</td>
      <td>จดทะเบียนเป็นผู้ส่งออกกาแฟรับ อนุญาตและขอหมายเลขประจําตัวผู้ส่งออก
เอกสาร/ใบรับรองที่จะได้รับ
บัตรผู้ส่งออก-นำเข้าสินค้ากาแฟรับอนุญาตและหมายเลขประจำตัวผู้ส่งออก
หลักฐานที่ใช้ยื่น
• คําร้องขอมีบัตรประจําตัวผู้ส่งออก-นําเข้าสินค้า
• สําเนาหนังสือรับรองการจดทะเบียนบริษัท ไม่เกิน 3 เดือน
• สําเนาหนังสือรับรองการจดทะเบียนภาษีมูลค่าเพิ่ม
• สําเนาบัตรประชาชนกรรมการหรืออื่น
</td>
      <td>กรมการค้าต่างประเทศ</td>
    </tr>
    <tr>
      <td>5</td>
      <td>ขอหนังสือรับรองถิ่นกําเนิดสินค้า</td>
      <td>
        
        <p>
  หนังสือรับรองถิ่นกำเนิดสินค้าทั่วไป Certificate of Origin (C/O) เป็นหนังสือรับรองที่ออกให้ผู้ส่งออกเพื่อแสดงว่าสินค้ามีถิ่นกำเนิดในประเทศใด และผลิตได้ถูกต้องตามกฎว่าด้วยถิ่นกำเนิดสินค้า ซึ่งใบ C/O จะมีอยู่ 2 ประเภทด้วยกันดังนี้
</p>

<ol>
  <li>
    <p><strong>หนังสือรับรองถิ่นกำเนิดสินค้าทั่วไป</strong> คือเอกสารที่นำไปใช้รับรองถิ่นกำเนิดสินค้า แต่ไม่สามารถนำไปขอยกเว้นหรือลดหย่อนภาษีอากรขาเข้าได้ เรียกว่า Ordinary Certificate of Origin</p>
  </li>
  <li>
    <p><strong>หนังสือรับรองถิ่นกำเนิดสินค้าแบบพิเศษ</strong> ที่นำไปใช้เพื่อขอรับสิทธิพิเศษทางภาษีศุลกากร (ขอยกเว้น หรือลดหย่อนภาษีอากรขาเข้า) เรียกว่า Preferential Certificate of Origin</p>
    <ul>
      <li>
        <p><strong>Ordinary Certificate of Origin</strong> ขอเรียกสั้นๆว่าแบบทั่วไป แบบทั่วไปนี้คือเอกสารที่ใช้เพื่อยืนยันกับผู้ซื้อว่า สินค้าที่ส่งออกไปนั้นประกอบด้วยวัตถุดิบภายในประเทศ มีการผลิต หรือผ่านขั้นตอนกระบวนการผลิตภายในประเทศ</p>
      </li>
      <li>
        <p><strong>Preferential Certificate of Origin</strong> ศัพท์นำเข้าส่งออกเราเรียกเอกสารสิ่งนี้ว่า "ฟอร์ม" ตัวอย่างเช่น FORM E (ฟอร์มอี) ซึ่งเป็น C/O ที่รับรองว่าสินค้าถูกผลิตในประเทศจีน และ FORM E นี้ ที่ใช้มาขอยกเว้น/ลดหย่อนภาษีอากรขาเข้าจากกรมศุลกากร C/O ที่ใช้ยกเว้นหรือลดหย่อนภาษีได้จึงเป็นชนิดพิเศษ</p>
      </li>
    </ul>
  </li>
</ol>

<p>
  หนังสือรับรองถิ่นกำเนิดสินค้า การใช้สิทธิพิเศษทางการค้าภายใต้เขตการค้าเสรีต่างๆ เป็นหนังสือรับรองฯ  ที่ออกให้แก่ผู้ส่งออกเพื่อใช้ในการขอรับสิทธิพิเศษตามความตกลงว่าด้วยเขตการค้าเสรีต่างๆ ได้แก่
</p>

<ul>
  <li>ความตกลงว่าด้วยเขตการค้าเสรีอาเซียน-จีน ใช้ Form E</li>
</ul>

<p><strong>หมายเหตุ :</strong></p>
<ul>
  <li>การขอหนังสือรับรองถิ่นกำเนิดสินค้าสามารถขอล่วงหน้าก่อนการส่งออกได้ 3 วันทำการ (ยกเว้นการขอฟอร์ม D ไปประเทศอินโดนีเซีย ไม่สามารถขอล่วงหน้าได้)</li>
  <li>การขอหนังสือรับรองถิ่นกำเนิดสินค้าสามารถขอย้อนหลังได้โดยขึ้นอยู่กับระเบียบปฏิบัติ (Operational Certification Procedure: OCP) ของแต่ละระบบสิทธิพิเศษทางการค้า ซึ่งส่วนใหญ่จะออกหนังสือรับรองถิ่นกำเนิดสินค้าย้อนหลังได้ไม่เกิน 1 ปี นับจากวันที่สินค้าออกจากประเทศไทย</li>
</ul>


      </td>
      <td>กรมการค้าต่างประเทศ</td>
    </tr>
    <tr>
      <td>6</td>
      <td>ขอใบอนุญาตแสดงเครื่องหมายมาตรฐานผลิตภัณฑ์อุตสาหกรรม (ทั่วไป)</td>
      <td><h3>ขั้นตอนในการดำเนินการ</h3>
<ol>
  <li>
    <p>ยื่นขอใบรับอนุญาตแสดงเครื่องหมายมาตรฐานผลิตภัณฑ์อุตสาหกรรม (แบบ มอ.1)</p>
  </li>
  <li>
    <p>การขอใบรับอนุญาตแสดงเครื่องหมายมาตรฐานผลิตภัณฑ์อุตสาหกรรม</p>
    <ol>
      <li>
        <p>เจ้าหน้าที่ตรวจสอบคำขอพร้อมหลักฐานที่ใช้ประกอบสำหรับการจดทะเบียนผลิตภัณฑ์</p>
      </li>
      <li>
        <p>เจ้าหน้าที่ตรวจพิจารณาผล การตรวจสอบข้อมูลผลิตภัณฑ์ส่งออก</p>
      </li>
      <li>
        <p>ชำระค่าธรรมเนียม</p>
      </li>
      <li>
        <p>เจ้าหน้าที่ดำเนินการออกใบรับอนุญาตแสดงเครื่องหมายมาตรฐานผลิตภัณฑ์อุตสาหกรรม</p>
      </li>
    </ol>
  </li>
</ol>

</td>
      <td>สำนักงานมาตรฐานผลิตภัณฑ์อุตสาหกรรม (สมอ.)</td>
    </tr>
    <tr>
      <td>7</td>
      <td>ขอหนังสือรับรองผลิตภัณฑ์ (Certificate of Free Sale)</td>
      <td>ขั้นตอนในการดำเนินการ
การยื่นคำขอหนังสือรับรองสถานที่ผลิตและผลิตภัณฑ์อาหารเพื่อการส่งออก
Certificate of Manufacturer, Certificate of Free Sale และ Certificate of Ingredient
http://food.fda.moph.go.th/ESub/document/manual/Certificate.pdf
</td>
      <td>สำนักงานคณะกรรมการอาหารและยา (อย.)</td>
    </tr>
    <tr>
      <td>8</td>
      <td>ขอใช้เครื่องหมายรับรองฮาลาล และขอใบรับรองผลิตภัณฑ์ฮาลาลเพื่อการส่งออก (ถ้ามี)</td>
      <td>(ถ้ามี)</td>
      <td>สำนักงานคณะกรรมการกลางอิสลามแห่งประเทศไทย</td>
    </tr>
    <tr>
      <td>9</td>
      <td>บริการค้นหาข้อมูลอัตราภาษีและศูนย์บริการข้อมูล FTA Center</td>
      <td></td>
      <td>กรมเจรจาการค้าระหว่างประเทศ</td>
    </tr>
    <tr>
      <td>10</td>
      <td>บริการช่วยเหลือและสนับสนุนการทำธุรกิจส่งออกสินค้า</td>
      <td></td>
      <td>กรมส่งเสริมการค้าระหว่างประเทศ</td>
    </tr>
    <tr>
      <td>11</td>
      <td>ตรวจสอบชดเชยค่าภาษีอากรสินค้าส่งออกที่ผลิตในราชอาณาจักร</td>
      <td></td>
      <td>กรมศุลกากร</td>
    </tr>
    <tr>
      <td>12</td>
      <td>ขั้นตอนการผ่านพิธีการศุลกากรขาออก</td>
      <td></td>
      <td>กรมศุลกากร</td>
    </tr>
    <tr>
      <td>13</td>
      <td>ผู้ส่งออกกาแฟรับอนุญาตต้องรายงานปริมาณการรับซื้อและการส่งออกเมล็ดกาแฟดิบ</td>
      <td>
        <ol>
  <li>
    <p>
      ผู้ส่งออกกาแฟรับอนุญาต จะต้องรายงานปริมาณการรับซื้อและการส่งออกเมล็ดกาแฟดิบ 
      ต่อกรมการค้าต่างประเทศเป็นประจำทุกเดือน ภายใน 7 วันทำการของเดือนถัดไป ตามแบบพิมพ์ที่กำหนด
    </p>
  </li>
  <li>
    <p>
      เมื่อสินค้าผ่านพิธีการตรวจปล่อยทางศุลกากรไปแล้ว ผู้ส่งออกจะต้องส่งสำเนาใบแรก (First Copy) 
      ของหนังสือรับรองถิ่นกำเนิดสินค้ากาแฟ พร้อมใบตราส่งสินค้า ให้กรมการค้าต่างประเทศ 
      ภายใน 7 วันทำการหลังจากวันที่ส่งออกสินค้า
    </p>
  </li>
</ol>

      </td>
      <td>กรมการค้าต่างประเทศ</td>
    </tr>
  </tbody>
</table>
</div>
<p>Links:</p>
<ul>
  <li><a href="https://www.moc.go.th/th/bizinfo/index/indexitem/itemID/298/itemCID/1789" target="_blank">https://www.moc.go.th/th/bizinfo/index/indexitem/itemID/298/itemCID/1789</a></li>
  <li><a href="https://www.dft.go.th/Portals/0/f@25540331-1112427037.pdf" target="_blank">https://www.dft.go.th/Portals/0/f@25540331-1112427037.pdf</a></li>
  <li><a href="https://onestopservice.ditp.go.th/file/9.29.pdf" target="_blank">https://onestopservice.ditp.go.th/file/9.29.pdf</a></li>
</ul>
<div class="container">
    <h2 class="text-center">Coffee Images</h2>
    <img src="@/assets/coffee-image1.jpg" class="img-fluid" alt="Coffee Image 1">
    <img src="@/assets/coffee-image2.png" class="img-fluid" alt="Coffee Image 2">
</div>





    </div>
  </template>
  
  <script>
  export default {
    name: 'CoffeePage' // เปลี่ยนชื่อเป็นหลายคำ
  }
  </script>
  