<template>
  <div>
 
        <!-- Header Section -->
    <div class="header">
       Service
    </div>
    <div class="container py-5">
      
        <div class="row p-5">
      
            <div class="col-md-4 mb-4">
                <div class="service-card">
                    <img src="@/assets/csr_img.png" class="h-50" alt="Online Market">
                    <div class="service-text p-3">
                        <h3 class="primary-text">Online Market</h3>
                      
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-4">
                <div class="service-card">
                    <img src="@/assets/bissness_matching.png" class="h-50" alt="Business Matching">
                    <div class="service-text p-3">
                        <h3 class="primary-text">Business Matching</h3>
                     
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-4">
                <div class="service-card">
                    <img src="@/assets/2ctic.png" class="h-50" alt="Law and Regulation">
                    <div class="service-text p-3">
                        <h3 class="primary-text">2CTIC</h3>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ServicePage' // เปลี่ยนชื่อเป็นหลายคำ
}
</script>



<style>
        .header {
            background-image: url('https://marketweb.9ood.net/assets/bg-mini-header.jpg'); /* Placeholder for header image */
            background-size: cover;
            background-position: center;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ff6f47;
            font-size: 2rem;
            font-weight: bold;
            width:100%;
        }
        .service-title {
            font-size: 1.5rem;
            font-weight: bold;
            text-align: center;
            margin-bottom: 20px;
        }

        .service-card {
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
            overflow: hidden;
        }

        .service-card img {
            width: 100%;
            height: auto;
            border-bottom: 1px solid #ddd;
        }

        .service-text {
            text-align: center;
            margin-top: 15px;
        }

        .primary-text{
            color:#013E77;
        }
    </style>
        