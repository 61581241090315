<template>
  <header class="custom-header">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <!-- Logo -->
        <div class="col-md-2 col-12 text-center">
          <div class="logo">
            <img src="https://marketweb.9ood.net/assets/logo-head.png" alt="Sino Thai Trade" class="logo-img w-100">
          </div>
        </div>

        <!-- Search bar -->
        <div class="col-md-6 col-12 d-flex align-items-center">
          <div class="search-bar">
            <input type="text" class="form-control custom-search-input" placeholder="Search">
            <button class="btn custom-search-btn"><i class="fas fa-search"></i></button>
          </div>
        </div>

        <!-- Right side icons and text -->
        <div class="col-md-4 col-12 d-flex justify-content-end align-items-center">
          <!-- Content for Desktop (PC) -->
          <div id="pc" class="d-none d-md-flex justify-content-end align-items-center">
            <div class="icon-nav me-3">
              <i class="fas fa-qrcode"></i>
              <div class="icon-nav-text">
                Download the<br><span style="font-size:8px;">Sino Thai Trade App</span>
              </div>
            </div>

           <div class="language-selector dropdown me-3">
    <a href="#" class=" dropdown-toggle" type="button" id="languageDropdown" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fas fa-flag-usa"></i> TH 
    </a>


        <ul class="dropdown-menu" aria-labelledby="languageDropdown">
          <li><a class="dropdown-item text-dark" href="https://th.sinothaitrade.com/" rel="noopener noreferrer">ไทย</a></li>
<li><a class="dropdown-item text-dark" href="https://cn.sinothaitrade.com/"  rel="noopener noreferrer">中文</a></li>
<li><a class="dropdown-item text-dark" href="https://en.sinothaitrade.com/" rel="noopener noreferrer">English</a></li>

          </ul>




</div>

            <div class="icon-nav me-3">
              <i class="fas fa-user"></i>
              <div class="icon-nav-text">
                Welcome <span style="font-size:8px;">Sign in / Register</span>
              </div>
            </div>
  <a href="https://shop.sinothaitrade.com/index.php/checkout">
            <div class="icon-nav text-center me-3">
             
              <i class="fas fa-shopping-cart"></i>
              <div class="icon-nav-text">
                <span class="rounded-pill bg-light text-dark" style="width:30px;">0</span>
                <span style="font-size:8px; margin-top:3px;">Cart</span>
              </div>
              
            </div> 
            </a>
          </div>

          <!-- Content for Mobile (เรียงไอคอนจากซ้ายไปขวา) -->
          <div id="mobile" class="d-flex justify-content-between align-items-center d-md-none w-100 mt-3">
            <div class="col d-flex justify-content-center" @click="toggleSidebar" id="sidebarToggle">
              <i class="fas fa-bars"></i>
            </div>
            <div class="col d-flex justify-content-center">
              <i class="fas fa-qrcode"></i>
            </div>
            <div class="col d-flex justify-content-center">
              <i class="fas fa-flag-usa"></i>
            </div>
            <div class="col d-flex justify-content-center">
              <i class="fas fa-user"></i>
            </div>
            <div class="col d-flex justify-content-center">
              <i class="fas fa-shopping-cart"></i>
            </div>
          </div>
        </div>
      </div>

      <!-- Sidebar (hidden by default) -->
      <div id="sidebar" class="sidebar" :class="{ 'active': isSidebarOpen }">
        <button class="close-btn" @click="toggleSidebar">&times;</button>
        <div class="dropdown all-services mt-5 d-flex justify-content-center">
          <button class="btn dropdown-toggle custom-dropdown w-100" type="button" style="margin-left: 20px;" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fas fa-bars"></i> All Services
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li><router-link class="dropdown-item" to="/all_services">Service 1</router-link></li>
            <li><router-link class="dropdown-item" to="/all_services">Service 2</router-link></li>
            <li><router-link class="dropdown-item" to="/all_services">Service 3</router-link></li>
          </ul>
        </div>

        <ul>
          <li class="nav-item"><router-link class="nav-link" :class="{ active: $route.path === '/' }" to="/">Home</router-link></li>
          <li class="nav-item"><router-link class="nav-link" :class="{ active: $route.path === '/about' }" to="/about">About</router-link></li>
          <li class="nav-item"><router-link class="nav-link" :class="{ active: $route.path === '/product' }" to="/product">Products</router-link></li>
          <li class="nav-item"><router-link class="nav-link" :class="{ active: $route.path === '/community' }" to="/community">Community</router-link></li>
          <li class="nav-item"><router-link class="nav-link" :class="{ active: $route.path === '/academy' }" to="/academy">Academy</router-link></li>
          <li class="nav-item"><router-link class="nav-link" :class="{ active: $route.path === '/services' }" to="/services">Services</router-link></li>
        </ul>
      </div>

      <!-- Navigation Menu -->
      <div class="row mt-3 d-none d-md-block">
        <div class="col-md-12 d-flex align-items-center justify-content-between">
          <!-- Hamburger menu button -->
          <div class="dropdown all-services">
            <button class="btn dropdown-toggle custom-dropdown btn-sm w-20" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fas fa-bars"></i> All Services
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li><router-link class="dropdown-item text-dark" to="/all_services">Service 1</router-link></li>
              <li><router-link class="dropdown-item text-dark" to="/all_services">Service 2</router-link></li>
              <li><router-link class="dropdown-item text-dark" to="/all_services">Service 3</router-link></li>
            </ul>
          </div>

          <!-- Main navigation -->
          <nav class="main-nav">
            <ul class="nav justify-content-center">
              <li class="nav-item mr-3">
                <router-link class="nav-link" :class="{ active: $route.path === '/' }" to="/">Home</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :class="{ active: $route.path === '/about' }" to="/about">About</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :class="{ active: $route.path === '/product' }" to="/product">Products</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :class="{ active: $route.path === '/community' }" to="/community">Community</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :class="{ active: $route.path === '/academy' }" to="/academy">Academy</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :class="{ active: $route.path === '/services' }" to="/services">Services</router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>

  <main class="container-fluid m-0 p-0" style="min-height:500px;">
      <router-view></router-view>
    </main>



        <!-- Footer -->
        <footer class="bg-dark text-white pt-4">
        <div class="container">
            <div class="row">
                <!-- Company Info -->
                <div class="col-md-4 col-12">
                    <h5 class="mb-3">Sino Thai Trade</h5>
                    <p>Leading trade and service provider in the industry. Offering quality products and services.</p>
                </div>

                <!-- Quick Links -->
                <div class="col-md-4 col-12">
                    <h5 class="mb-3">Quick Links</h5>
                    <ul class="list-unstyled">
                        <li><a href="#" class="text-white text-decoration-none">Home</a></li>
                        <li><a href="#" class="text-white text-decoration-none">About Us</a></li>
                        <li><a href="#" class="text-white text-decoration-none">Products</a></li>
                        <li><a href="#" class="text-white text-decoration-none">Services</a></li>
                    </ul>
                </div>

                <!-- Contact Info -->
                <div class="col-md-4 col-12">
                    <h5 class="mb-3">Contact Us</h5>
                    <p><i class="fas fa-map-marker-alt"></i> 123 Trade Street, Bangkok, Thailand</p>
                    <p><i class="fas fa-phone-alt"></i> +66 1234 5678</p>
                    <p><i class="fas fa-envelope"></i> info@sinothaitrade.com</p>
                </div>
            </div>

            <!-- Social Media Links -->
            <div class="row mt-4">
                <div class="col-md-12 text-center">
                    <a href="#" class="text-white mx-2"><i class="fab fa-facebook-f"></i></a>
                    <a href="#" class="text-white mx-2"><i class="fab fa-twitter"></i></a>
                    <a href="#" class="text-white mx-2"><i class="fab fa-linkedin-in"></i></a>
                    <a href="#" class="text-white mx-2"><i class="fab fa-instagram"></i></a>
                </div>
            </div>

            <!-- Footer Bottom -->
            <div class="row mt-4">
                <div class="col-md-12 text-center">
                    <p>&copy; 2024 Sino Thai Trade. All Rights Reserved.</p>
                </div>
            </div>
        </div>
    </footer>




</template>

<script>
export default {
  name: 'SiteHeader',
  data() {
    return {
      isSidebarOpen: false
    }
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    }
  }
}
</script>


<style scoped>
body {
    font-family: 'Kanit', sans-serif;
}

/* Download app section */
.download-app {
    display: flex;
    align-items: center;
}

.download-app i {
    font-size: 24px;
    margin-right: 10px;
}

.download-text {
    font-size: 12px;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
}

  /* Download app section */
  .icon-nav {
    display: flex;
    align-items: center;
}

.icon-nav i {
    font-size: 24px;
    margin-right: 10px;
}



.icon-nav-text {
    font-size: 12px;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
}

/* Header styles */
.custom-header {
background: linear-gradient(90deg, #0D8BC7 0%, #1E6598 100%);
padding: 15px 0;
color: white;
}
.logo-img {
    max-width: 320px;
}

.all-services {
    margin-right: 20px;
}

.custom-dropdown {
    background: white;
    color: #333;
    border: none;
    padding: 10px 15px;
    border-radius: 50px;
}

.search-bar {
    position: relative;
    flex-grow: 1;
}

.custom-search-input {
    border-radius: 50px;
    padding: 10px 20px;
    width: 100%;
}

.custom-search-btn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    color: #333;
}

.download-app, .language-selector, .user-auth, .cart {
    margin-left: 20px;
    color: white;
}

.download-app i, .language-selector i, .user-auth i, .cart i {
    margin-right: 5px;
}

.language-selector i.fa-chevron-down {
    margin-left: 5px;
}

a {
    color: white;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* Main navigation */
.main-nav .nav-link {
    color: white;
    font-size: 16px;
    padding: 10px 20px;
}

.main-nav .nav-link:hover {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
}

.main-nav .nav-link.active {
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
}

.main-nav {
    flex-grow: 1;
    display: flex;
    justify-content: center;
}

/* Body section */
.background-image {
    width: 100%;
    height: 85vh;
    background-image: url('@/assets/bg-main.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.branding img {
    width: 100%;
}

.description {
    text-align: left;
    margin-top: 20px;
    color: #444;
    font-size:22px;
}

.auth-buttons {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.auth-buttons .btn {
    width: 120px;
}

.social-login {
    text-align: center;
    margin-top: 15px;
}

.social-login .btn {
    margin: 0 5px;
}

@media (max-width: 768px) {
    .search-bar {
        width: 100%;
    }

    .logo-img {
        max-width: 270px;
        margin:15px;
    }

    .auth-buttons .btn {
        width: 100px;
    }

    .main-nav .nav-link {
        padding: 5px 10px;
    }

    .description {
        text-align: center;
    }

    .download-app {
        justify-content: center;
    }
}


/* Sidebar styling */
.sidebar {
height: 100%;
width: 250px;
position: fixed;
top: 0;
left: -250px; /* เริ่มต้นซ่อน sidebar */
background-color: #f8f9fa;
overflow-x: hidden;
transition: left 0.3s ease-in-out;
padding-top: 20px;
z-index: 1060;
}

.sidebar.active {
  left: 0; /* แสดง sidebar เมื่อ active */
}

.sidebar ul {
padding-left: 0;
}

.sidebar ul li {
padding: 8px 16px;
list-style-type: none;
}

.sidebar ul li a {
text-decoration: none;
color: #333;
display: block;
}

.sidebar ul li a:hover {
background-color: #ddd;
}

.close-btn {
position: absolute;
top: 10px;
right: 20px;
font-size: 24px;
background: none;
border: none;
cursor: pointer;
}

.close-btn:hover {
color: #ff0000;
}




/* Style for the floating button */
#sidebarToggle {
/* position: fixed;*/
top: 20px; /* Adjust distance from top */
left: 20px; /* Adjust distance from left */
z-index: 1050; /* Ensure it stays above other content */

color: white;
}

/* สำหรับหน้าจอขนาดเล็ก เช่น มือถือ */
@media (max-width: 768px) {
.background-image {
background-position: right;
height:75vh;
}

.background-image img {
margin:40px;
width:90%;
}

.branding {
text-align: center;
}
}


#preloader {
position: fixed;
width: 100%;
height: 100%;
background-color: white;
z-index: 9999;
display: flex;
justify-content: center;
align-items: center;
}

body.loading #preloader {
color:#1280BA;
display: flex;
}

body.loaded #preloader {
display: none;
}

.bg-dark-blue{
background-color:#003E77;
color:#fff;
}
</style>
